@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

* {
    margin: 0;
    font-family: 'Poiret One', cursive;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
